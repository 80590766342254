import { defineNuxtRouteMiddleware } from '#imports';

export default defineNuxtRouteMiddleware(() => {
  // log message only in client-side requests and in production
  if (process.server || process.env.NODE_ENV !== 'production') return;

  // eslint-disable-next-line no-console
  console.log(
    `%cХочешь присоединиться к техотделу DOXA 🧑‍💻? Пиши нам на join@doxa.team 📪`,
    `padding: 8px; border-radius: 10px; background-color: rgb(100, 28, 114); font-size: 2em; font-family: system-ui;`
  );
});
