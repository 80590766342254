import imageUrlBuilder from '@sanity/image-url';
import { defineNuxtPlugin, useSanity } from '#imports';

export default defineNuxtPlugin(() => {
  const builder = imageUrlBuilder(useSanity().config);
  function urlFor(source) {
    return builder.image(source).auto('format');
  }
  return {
    provide: { urlFor },
  };
});
