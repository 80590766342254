import { defineNuxtRouteMiddleware, navigateTo } from '#imports';

// redirects paths like /page/ to /page with code 301
// based on https://github.com/nuxt/nuxt/issues/15462#issuecomment-1407374859
// eslint-disable-next-line consistent-return
export default defineNuxtRouteMiddleware((to) => {
  if (to.path !== '/' && to.path.endsWith('/')) {
    const { path, query, hash } = to;
    const nextPath = path.replace(/\/+$/, '') || '/';
    const nextRoute = { path: nextPath, query, hash };
    return navigateTo(nextRoute, { redirectCode: 301 });
  }
});
