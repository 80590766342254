import { default as aboutp1uBgSDFY0Meta } from "/home/runner/work/doxa-frontend/doxa-frontend/pages/about.vue?macro=true";
import { default as actGgsMiwgdnDMeta } from "/home/runner/work/doxa-frontend/doxa-frontend/pages/act.vue?macro=true";
import { default as antiwarletterhWw8TlMgZwMeta } from "/home/runner/work/doxa-frontend/doxa-frontend/pages/antiwarletter.vue?macro=true";
import { default as _91slug_93206TkuXwyfMeta } from "/home/runner/work/doxa-frontend/doxa-frontend/pages/articles/[slug].vue?macro=true";
import { default as index6HOoV0VkmmMeta } from "/home/runner/work/doxa-frontend/doxa-frontend/pages/articles/index.vue?macro=true";
import { default as contact_45usnhy37yuTHYMeta } from "/home/runner/work/doxa-frontend/doxa-frontend/pages/contact-us.vue?macro=true";
import { default as actytCoA5meffMeta } from "/home/runner/work/doxa-frontend/doxa-frontend/pages/en/act.vue?macro=true";
import { default as indexzNabpuygUHMeta } from "/home/runner/work/doxa-frontend/doxa-frontend/pages/index.vue?macro=true";
import { default as _91slug_938a7ilnoRrBMeta } from "/home/runner/work/doxa-frontend/doxa-frontend/pages/main-article-card/[slug].vue?macro=true";
import { default as _91slug_93Shk7Nho9qsMeta } from "/home/runner/work/doxa-frontend/doxa-frontend/pages/members/[slug].vue?macro=true";
import { default as _91slug_93OxrQXKWTVLMeta } from "/home/runner/work/doxa-frontend/doxa-frontend/pages/news/[slug].vue?macro=true";
import { default as indexJbC9KVmwMRMeta } from "/home/runner/work/doxa-frontend/doxa-frontend/pages/news/index.vue?macro=true";
import { default as pride_45feedgEimkGpUWSMeta } from "/home/runner/work/doxa-frontend/doxa-frontend/pages/pride-feed.vue?macro=true";
import { default as pridekIgD82pSqLMeta } from "/home/runner/work/doxa-frontend/doxa-frontend/pages/pride.vue?macro=true";
import { default as _91slug_93mpZ3aPJMGyMeta } from "/home/runner/work/doxa-frontend/doxa-frontend/pages/sections/[slug].vue?macro=true";
import { default as indexCOOy6uJpc0Meta } from "/home/runner/work/doxa-frontend/doxa-frontend/pages/specials/index.vue?macro=true";
import { default as z_45ratingfvEULzzuEuMeta } from "/home/runner/work/doxa-frontend/doxa-frontend/pages/specials/z-rating.vue?macro=true";
import { default as _91slug_93MzbLdCbj9sMeta } from "/home/runner/work/doxa-frontend/doxa-frontend/pages/themes/[slug].vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/home/runner/work/doxa-frontend/doxa-frontend/pages/about.vue")
  },
  {
    name: "act",
    path: "/act",
    component: () => import("/home/runner/work/doxa-frontend/doxa-frontend/pages/act.vue")
  },
  {
    name: "antiwarletter",
    path: "/antiwarletter",
    component: () => import("/home/runner/work/doxa-frontend/doxa-frontend/pages/antiwarletter.vue")
  },
  {
    name: "articles-slug",
    path: "/articles/:slug()",
    component: () => import("/home/runner/work/doxa-frontend/doxa-frontend/pages/articles/[slug].vue")
  },
  {
    name: "articles",
    path: "/articles",
    component: () => import("/home/runner/work/doxa-frontend/doxa-frontend/pages/articles/index.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/home/runner/work/doxa-frontend/doxa-frontend/pages/contact-us.vue")
  },
  {
    name: "en-act",
    path: "/en/act",
    component: () => import("/home/runner/work/doxa-frontend/doxa-frontend/pages/en/act.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/doxa-frontend/doxa-frontend/pages/index.vue")
  },
  {
    name: "main-article-card-slug",
    path: "/main-article-card/:slug()",
    component: () => import("/home/runner/work/doxa-frontend/doxa-frontend/pages/main-article-card/[slug].vue")
  },
  {
    name: "members-slug",
    path: "/members/:slug()",
    component: () => import("/home/runner/work/doxa-frontend/doxa-frontend/pages/members/[slug].vue")
  },
  {
    name: "news-slug",
    path: "/news/:slug()",
    component: () => import("/home/runner/work/doxa-frontend/doxa-frontend/pages/news/[slug].vue")
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/home/runner/work/doxa-frontend/doxa-frontend/pages/news/index.vue")
  },
  {
    name: "pride-feed",
    path: "/pride-feed",
    component: () => import("/home/runner/work/doxa-frontend/doxa-frontend/pages/pride-feed.vue")
  },
  {
    name: "pride",
    path: "/pride",
    component: () => import("/home/runner/work/doxa-frontend/doxa-frontend/pages/pride.vue")
  },
  {
    name: "sections-slug",
    path: "/sections/:slug()",
    component: () => import("/home/runner/work/doxa-frontend/doxa-frontend/pages/sections/[slug].vue")
  },
  {
    name: "specials",
    path: "/specials",
    component: () => import("/home/runner/work/doxa-frontend/doxa-frontend/pages/specials/index.vue")
  },
  {
    name: "specials-z-rating",
    path: "/specials/z-rating",
    component: () => import("/home/runner/work/doxa-frontend/doxa-frontend/pages/specials/z-rating.vue")
  },
  {
    name: "themes-slug",
    path: "/themes/:slug()",
    component: () => import("/home/runner/work/doxa-frontend/doxa-frontend/pages/themes/[slug].vue")
  }
]