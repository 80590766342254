import { defineNuxtPlugin } from '#imports';

export default defineNuxtPlugin(() => {
  const userAgent = typeof navigator !== 'undefined' ? navigator.userAgent : '';
  const isMobile = /mobile/i.test(userAgent);

  return {
    provide: {
      isMobile,
    },
  };
});
