import validate from "/home/runner/work/doxa-frontend/doxa-frontend/node_modules/nuxt/dist/pages/runtime/validate.js";
import join_45is_45log_45global from "/home/runner/work/doxa-frontend/doxa-frontend/middleware/join-is-log.global.js";
import redirect_45traling_45slash_45global from "/home/runner/work/doxa-frontend/doxa-frontend/middleware/redirect-traling-slash.global.js";
import manifest_45route_45rule from "/home/runner/work/doxa-frontend/doxa-frontend/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  join_45is_45log_45global,
  redirect_45traling_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}