export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"msapplication-TileColor","content":"#603cba"},{"name":"theme-color","content":"#2b0049"}],"link":[{"rel":"apple-touch-icon","sizes":"180x180","href":"/apple-touch-icon.png"},{"rel":"icon","sizes":"32x32","href":"/favicon-32x32.png"},{"rel":"icon","sizes":"16x16","href":"/favicon-16x16.png"},{"rel":"icon","href":"/favicon.ico","sizes":"any"},{"rel":"mask-icon","href":"/safari-pinned-tab.svg","color":"#2b0049"},{"rel":"manifest","href":"/manifest.webmanifest"},{"rel":"preload","as":"font","type":"font/woff2","crossorigin":"anonymous","href":"https://watermeloncdn.win/fonts/Unbounded-Variable.woff2"}],"style":[{"children":"@font-face{font-family:\"Unbounded Variable\";font-weight:100 1000;font-style:normal;font-display:swap;src:url('https://watermeloncdn.win/fonts/Unbounded-Variable.woff2') format('woff2');}"}],"script":[],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"